<template>
  <div class="group flex h-full w-full rounded-sm border"
    :class="[slot.roomOverlapped?.isOverlapped ? 'border-red-500' : 'border-primary']"
    style="min-height: 8rem"
    :style="{ borderColor: colorResolver }">

    <schedule-option-modal :show="optionModalVisible"
      v-model="slot.note"
      @submit="onOptionModalSubmit"
      @submit-note="onNoteSubmit"
      @close="() => optionModalVisible = false"></schedule-option-modal>

    <time-slot-modal :show="timeSlotModalVisible"
      :time-periods="slot.periods"
      :week-day="weekDay"
      :student-year="studentYear"
      :room-id="roomId"
      @close="() => timeSlotModalVisible = false"></time-slot-modal>

    <div class="h-auto w-2"
      :class="[slot.roomOverlapped?.isOverlapped ? 'bg-red-500' : 'bg-primary']"
      style="min-height: 8rem "
      :style="{ backgroundColor: colorResolver }"></div>

    <div class="w-full px-2 py-2">
      <div class="flex w-full justify-between">
        <div class="flex flex-col">
          <div class="flex justify-between">
            <router-link :to="{
      name: 'courseMatching',
      query: {
        semesterId: slot.semesterId,
        degreeId: slot.degreeId,
        courseId: slot.courseId,
      },
    }">
              <p class="cursor-pointer font-medium"
                data-cy="slot-title">
                {{ slot.code }}
                {{ slot.name }}
                ·
                {{ slot.number }}
              </p>

            </router-link>


          </div>

          <div class="flex items-center">
            <IconBuilding class="fill-current" />
            <p class="ml-2"
              data-cy="slot-room">{{ slot.room }}</p>
          </div>

          <div class="flex mt-2">

            <svg class="svg-icon"
              style="width: 1.3em; height: 1.3em;vertical-align: middle;fill: currentColor;overflow: hidden;"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M935.292 958.682H729.154c-13.727 0-24.855-11.129-24.855-24.855v-38.712H320.181v38.712c0 13.727-11.129 24.855-24.855 24.855H90.189c-13.726 0-24.856-11.129-24.856-24.855V408.563c0-13.726 11.13-24.856 24.856-24.856h38.712V138.436c0-41.18 33.375-74.568 74.568-74.568h618.542c41.18 0 74.567 33.388 74.567 74.568v245.271h38.713c13.727 0 24.854 11.13 24.854 24.856v525.263c0.001 13.727-11.126 24.856-24.853 24.856zM832.866 152.436c0-13.727-11.128-24.856-24.854-24.856H216.469c-13.726 0-24.856 11.129-24.856 24.856v231.271h41.712c13.726 0 22.855 11.13 22.855 24.856v166.992h512.118V408.563c0-13.726 11.129-24.856 24.855-24.856h39.712V152.436z m63.572 294.984h-64.426v166.99c0 13.727-11.129 24.856-24.857 24.856H217.326c-13.728 0-24.856-11.13-24.856-24.856V447.419h-64.424v448.55H256.47v-38.712c0-13.727 11.129-24.855 24.856-24.855h460.829c13.729 0 24.857 11.129 24.857 24.855v38.712h129.426V447.42z" />
            </svg>

            <div class="ml-2 flex">
              <p>จำนวนรับ {{ slot.capacity }} คน</p>
            </div>
          </div>
        </div>

        <div class="flex flex-col gap-2 my-2">
          <button @click="showOptionModal">
            <svg xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4">
              <path stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.53 16.122a3 3 0 0 0-5.78 1.128 2.25 2.25 0 0 1-2.4 2.245 4.5 4.5 0 0 0 8.4-2.245c0-.399-.078-.78-.22-1.128Zm0 0a15.998 15.998 0 0 0 3.388-1.62m-5.043-.025a15.994 15.994 0 0 1 1.622-3.395m3.42 3.42a15.995 15.995 0 0 0 4.764-4.648l3.876-5.814a1.151 1.151 0 0 0-1.597-1.597L14.146 6.32a15.996 15.996 0 0 0-4.649 4.763m3.42 3.42a6.776 6.776 0 0 0-3.42-3.42" />
            </svg>

          </button>


          <button @click="showTimeSlotModal">
            <svg xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5">
              <path stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 4.5v15m7.5-7.5h-15" />
            </svg>



          </button>
        </div>
      </div>



      <hr class="my-1 text-gray" />



      <!-- <div v-if="canAccessCourseMatching"
        class="my-2 hidden w-auto group-hover:block">
        <p class="mb-2 font-medium">แทรกวิชาเรียนใหม่</p>
        <router-link v-for="time in slot.periods"
          :to="{
      name: 'courseMatching',
      query: {
        startTime: time,
        weekDay: weekDay,
        studentYear: studentYear,
        roomId: roomId,
      },
    }"
          data-cy="course-matching-links">
          <p class="mr-2 mt-1 hover:underline">{{ time }}</p>
        </router-link>

        <hr class="my-1 text-gray" />
      </div>

      <div v-if="slot.roomOverlapped?.isOverlapped"
        class="mb-4 text-red-500"
        data-cy="room-duplicated-links">
        <p class="font-bold">มีการใช้ห้องซ้ำกับวิชา</p>

        <router-link :to="{
      name: 'courseMatching',
      query: {
        semesterId: section.semester.id,
        degreeId: section.degree.id,
        courseId: section.course.id,
      },
    }"
          v-for="section in slot.roomOverlapped?.sections"
          :data-cy="`course-matching-link-courseCode-${section.course.code}-secNumber-${section.number}-courseName-${section.course.nameTh}`">
          <div class="flex items-start">
            <p>
              {{ section.course.code }}
              {{ section.number }} ·
              {{ section.course.nameTh }}
            </p>
          </div>
        </router-link>
      </div> -->



      <div v-if="slot.teacherOverlapped?.isOverlapped"
        data-cy="teacher-duplicated-links"
        class="font-bold text-red-500">
        อาจารย์ชนเวลาสอน
      </div>

      <p class="font-medium"
        :class="[
      slot.teacherOverlapped.overlappedTeacherIds.includes(slot.teacher.id)
        ? 'text-red-500'
        : '',
    ]"
        data-cy="slot-teacher">
        {{ slot.teacher.firstname }} {{ slot.teacher.lastname }}
      </p>

      <div class="flex"
        data-cy="slot-co-teachers">
        <p v-for="(teacher, index) in slot.coTeachers"
          class="mr-2"
          :class="[
      slot.teacherOverlapped.overlappedTeacherIds.includes(teacher.id)
        ? 'text-red-500'
        : '',
    ]"
          :data-cy="`slot-co-teacher-name-${teacher.firstname}`">
          {{ teacher.firstname }}
          <span v-if="index !== slot.coTeachers.length - 1">,</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import IconBuilding from "@/assets/icon/building.svg";
import IconAdd from "@/assets/icon/add.svg";
import BaseButtonIcon from "@/components/BaseButtonIcon";
import ScheduleItemFree from "@/components/ScheduleItemFree";
import InsertScheduleModal from "@/components/modal/InsertScheduleModal";
import ScheduleOptionModal from "@/components/modal/ScheduleOptionModal";
import TimeSlotModal from "@/components/modal/TimeSlotModal";

import { $vfm } from "vue-final-modal";
import { computed, ref } from "vue";
import axios from "axios";
import { useToast } from "vue-toast-notification";

export default {
  components: {
    ScheduleItemFree,
    BaseButtonIcon,
    IconAdd,
    IconBuilding,
    InsertScheduleModal,
    ScheduleOptionModal,
    TimeSlotModal,
  },
  props: {
    slot: {
      type: Object,
      default: null,
    },
    canAccessCourseMatching: {
      type: Boolean,
      default: false,
    },
    weekDay: {
      type: String,
      required: false,
    },
    studentYear: {
      type: String,
      required: false,
    },
    degreeId: {
      type: Number,
      required: false,
    },
    semesterId: {
      type: Number,
      required: false,
    },
    roomId: {
      type: Number,
      required: false,
    },
    startTime: {
      type: String,
      required: false,
    },
  },
  setup(props, context) {
    const optionModalVisible = ref(false);

    const showOptionModal = () => {
      optionModalVisible.value = true;
    }

    const tempUpdatedColor = ref(null);

    const colorResolver = computed(() => {
      if (props.slot.roomOverlapped?.isOverlapped) return ""
      if (tempUpdatedColor.value != null) return tempUpdatedColor.value;
      if (props.slot.color != null) return props.slot.color;

      return ""
    })

    const onOptionModalSubmit = async ({ color }) => {
      const response = await axios.put(
        `section-color/${props.slot.sectionId}`,
        {
          color: color
        }
      );

      optionModalVisible.value = false;
      tempUpdatedColor.value = color;
    }

    const onNoteSubmit = async (note) => {
      const response = await axios.put(
        `section-note/${props.slot.sectionId}`,
        {
          note: note
        }
      );

      useToast().success("บันทึกหมายเหตุแล้ว!");
      optionModalVisible.value = false;

    }

    const timeSlotModalVisible = ref(false);

    const showTimeSlotModal = () => {
      timeSlotModalVisible.value = true;
    }


    return { optionModalVisible, showOptionModal, onOptionModalSubmit, onNoteSubmit, tempUpdatedColor, colorResolver, timeSlotModalVisible, showTimeSlotModal }
  },
};
</script>

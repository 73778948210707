<template>
  <div class="schedule-table">
    <table>
      <thead>
        <tr>
          <th class="w-20" :style="{ backgroundColor: colorPrimary }">
            {{ groupTitle }}
          </th>
          <th v-for="slot in timeSlots" class="time-slot">
            {{ slot.format("HH:mm") }}
          </th>
        </tr>
      </thead>

      <tbody>
        <template v-for="(titleRow, index) in data">
          <tr v-for="(scheduleRows, rowIndex) in titleRow.rows">
            <th
              v-if="rowIndex == 0"
              class="text-center"
              :style="{ backgroundColor: colorSecondary }"
              :rowspan="titleRow.numberOfRows"
            >
              {{ titleRow.title }}
            </th>

            <td
              v-for="(slot, slotIndex) in scheduleRows"
              :colspan="slot.numberOfSlots"
            >
              <template v-if="!slot.isFree">
                <slot
                  name="itemSchedule"
                  :titleRow="titleRow.title"
                  :titleGroup="groupTitle"
                  :timeSlot="slot.timeSlot"
                  :canAccessCourseMatching="canAccessCourseMatching"
                  :slot="slot"
                >
                  <ScheduleItemDegree :slot="slot" />
                </slot>
              </template>

              <template v-else>
                <slot
                  name="itemFree"
                  :titleRow="titleRow.title"
                  :titleGroup="groupTitle"
                  :timeSlot="slot.timeSlot"
                  :canAccessCourseMatching="canAccessCourseMatching"
                >
                </slot>
              </template>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { computed, reactive, ref } from "vue";
import IconBuilding from "@/assets/icon/building.svg";
import { useScheduleTable } from "@/composables/schedule-table";
import { useRouter, useRoute } from "vue-router";
import ScheduleItemDegree from "@/components/ScheduleItemDegree";
import ScheduleItemFree from "@/components/ScheduleItemFree";
import { useCan } from "@/composables/authentication";

export default {
  props: {
    data: {
      type: Array,
      default: [],
    },
    groupTitle: {
      type: String,
      default: "",
    },
    colorPrimary: {
      type: String,
    },
    colorSecondary: {
      type: String,
    },
    rowTitleRender: {
      type: Function,
      default: (title) => title,
    },
  },
  components: {
    IconBuilding,
    ScheduleItemDegree,
    ScheduleItemFree,
  },
  setup(props, context) {
    const { timeSlots } = useScheduleTable();
    const router = useRouter();
    const { canAccessCourseMatching } = useCan();

    const navigateCourseMatching = (slot) => {
      const { semesterId, degreeId, courseId } = slot;

      router.push({
        name: "courseMatching",
        query: { semesterId, degreeId, courseId },
      });
    };

    return {
      timeSlots,
      navigateCourseMatching,

      canAccessCourseMatching,
    };
  },
};
</script>

<style scoped>
@import url("../css/schedule-table.css");
</style>

<style scoped>
th {
  width: 80px;
  padding: 6px 6px;
  @apply h-12;
}

td {
  width: 80px;
  padding: 6px 6px;
  @apply h-36;
}
</style>

import { computed } from "vue";
import { useStore } from "vuex";

const checkAccessMasterData = (user) => {
  return user.ability.master_data.main_menu == true;
};

const checkAccessCourseMatching = (user) => {
  return user.ability.course_matchng.main_menu == true;
};

const checkAccessClassSchedule = (user) => {
  return user.ability.class_schedule.main_menu == true;
};

const checkAccessRoomSchedule = (user) => {
  return user.ability.room_schedule.main_menu == true;
};

const checkAccessRoomBooking = (user) => {
  return user.ability.room_booking.main_menu == true;
};

const checkAccessWorkload = (user) => {
  return user.ability.workload.main_menu == true;
};

const checkAccessOverWorkload = (user) => {
  return user.ability.over_workload.main_menu == true;
};


// const checkAccessMasterData = (user) => {
//   return user.ability.master_data.main_menu == true;
//   return ["ADMIN", "ACADEMY_HEAD", "DEGREE_STAFF"].includes(user.originalRole.key);
// };

// const checkAccessCourseMatching = (user) => {
//   return user.ability.course_matchng.main_menu == true;

//   return ["ADMIN", "ACADEMY_HEAD", "DEGREE_STAFF"].includes(user.originalRole.key);
// };

// const checkAccessClassSchedule = (user) => {
//   return true; // Everyone can access;
// };

// const checkAccessRoomSchedule = (user) => {
//   return [
//     "ADMIN",
//     "ACADEMY_HEAD",
//     "DEGREE_STAFF",
//     "DEGREE_HEAD",
//     "TEACHER_COURSE",
//     "TEACHER_TEACHING",
//     "TEACHER_INTERNAL",
//     "STAFF",
//   ].includes(user.originalRole.key);
// };

// const checkAccessWorkload = (user) => {
//   return [
//     "ADMIN",
//     "ACADEMY_HEAD",
//     "DEGREE_STAFF",
//     "DEGREE_HEAD",
//     "TEACHER_COURSE",
//     "TEACHER_TEACHING",
//     "TEACHER_INTERNAL",
//   ].includes(user.originalRole.key);
// };

const useCan = () => {
  const store = useStore();
  const user = computed(() => store.state.authentication);

  const canAccessMasterData = computed(() => checkAccessMasterData(user.value));

  const canAccessCourseMatching = computed(() =>
    checkAccessCourseMatching(user.value)
  );

  const canAccessClassSchedule = computed(() =>
    checkAccessClassSchedule(user.value)
  );

  const canAccessRoomSchedule = computed(() =>
    checkAccessRoomSchedule(user.value)
  );

  const canAccessRoomBooking = computed(() =>
    checkAccessRoomBooking(user.value)
  );

  const canAccessOverWorkload = computed(() =>
    checkAccessOverWorkload(user.value)
  );

  const canAccessWorkload = computed(() => checkAccessWorkload(user.value));

  return {
    canAccessMasterData,
    canAccessCourseMatching,
    canAccessClassSchedule,
    canAccessRoomSchedule,
    canAccessWorkload,
    canAccessRoomBooking,
    canAccessOverWorkload
  };
};

export {
  useCan,
  checkAccessMasterData,
  checkAccessCourseMatching,
  checkAccessClassSchedule,
  checkAccessRoomSchedule,
  checkAccessWorkload,
  checkAccessOverWorkload,
};
